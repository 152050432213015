<script setup lang="ts">
const store = useStore();

const items = computed(() => [
  [
    {
      slot: "account",
      label: "",
      disabled: true,
    },
  ],
  [
    {
      label: "Delogare",
      icon: "i-material-symbols-logout-rounded",
      class: "text-red-500 dark:text-red-500",
      click: () => {
        navigateTo("/auth/logout");
      },
    },
  ],
]);
</script>

<template>
  <UDropdown mode="click" :items="items" :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }"
    :popper="{ strategy: 'absolute', placement: 'top' }" class="w-full">
    <template #default="{ open }">
      <UButton color="gray" variant="ghost" class="w-full" :label="formatName(store.user)"
        :class="[open && 'bg-gray-50 dark:bg-gray-800']">
        <template #leading>
          <UAvatar :src="store.user?.image" size="2xs" imgClass="object-cover" />
        </template>

        <template #trailing>
          <RoleBadge class="md:hidden" :user="store.user" />
          <UIcon name="i-heroicons-ellipsis-vertical" class="w-5 h-5 ml-auto" />
        </template>
      </UButton>
    </template>

    <template #account>
      <div class="text-left">
        <p>Autentificat cu</p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          {{ store.user?.email }}
        </p>
      </div>
    </template>
  </UDropdown>
</template>
