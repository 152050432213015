<script setup lang="ts">
const store = useStore();

const show = ref(false);
const checkinNotification = ref(store.checkinNotification);

watch(checkinNotification, (value) => {
  if (!["developer", "admin"].includes(store?.user.role) && (!value || value !== new Date().toISOString().split("T")[0])) {
    show.value = true;
  }
})

watch(show, (value) => {
  if (!value) {
    close();
  }
})

function goToCheckin() {
  close()
  navigateTo("/attendance/checkin");
}
function close() {
  store.updateCheckinNotification();
  show.value = false;
}
</script>

<template>
  <UDashboardModal v-model="show" title="Nu uita sa faci checkin!"
    description='Daca nu ai facut checkin azi, te rugam sa o faci acum. Pentru asta apasa pe butonul de mai jos sau mergi la pagina "Checkin" in meniu.'
    icon="i-heroicons-check-circle" :ui="{
      icon: { base: 'text-primary-500 dark:text-primary-400' } as any,
      footer: { base: 'ml-16' } as any
    }">
    <template #footer>
      <UButton @click="goToCheckin" label="Mergi la checkin" />
    </template>
  </UDashboardModal>
</template>
