<script setup lang="ts">
const dev = ref(true)
// const dev = ref(process.dev)

const appConfig = useAppConfig();
const store = useStore();
const route = useRoute();

const profileComplete = ref(false)

watch(() => store.user, () => {
  if (Object.keys(store.user).length) {
    profileComplete.value = !checkUserProfileComplete()
    uncommon.value = uncommonMapper()
  }
}, { deep: true })

const viewing = computed(() => {
  if (Object.keys(uncommon.value).length - 1 === 1 &&
    Object.keys(uncommon.value)[0] !== store.viewing) {
    store.view(Object.keys(uncommon.value)[0]);
  }

  return uncommon.value[store.viewing];
});

const modulesDropdownLinks = computed(() => {
  let links = {};
  for (const [key, value] of Object.entries(uncommon.value)) {
    if (value.click) {
      links[key] = value;
    }
  }
  return links;
})

const common = computed(() => {
  let links = [
    {
      label: "Pagina principala",
      icon: "i-heroicons-home",
      to: "/dashboard/",
    },
    {
      label: "Checkin",
      icon: "i-material-symbols-my-location-outline-rounded",
      to: "/attendance/checkin"
    }
  ]
  if (store.user.role !== "staff") {
    links.push({
      label: "Tranzacții",
      icon: "i-heroicons-receipt-refund",
      to: "/dashboard/invoices",
    })
  }
  return links
})


const uncommon = ref(uncommonMapper() as any)

function uncommonMapper() {
  let modules = {}

  if (permissionsAllow("venues")) {
    modules.venues = {
      tag: "venues",
      label: "Evenimente",
      click: () => {
        store.view("venues");
      },
      children: [
        {
          label: "Evenimente",
          to: "/venues/events",
          icon: "i-heroicons-calendar",
          children: [
            {
              label: "Calendar",
              to: "/venues/events/calendar",
            },
            {
              label: "Lista",
              to: "/venues/events/list",
            },
          ],
        },
        {
          label: "Banchete",
          to: "/venues/events",
          icon: "i-material-symbols-file-present-outline-rounded",
          children: [
            {
              label: "Meniuri",
              // icon: "i-material-symbols-file-present-outline-rounded",
              to: "/venues/menus",
              active: route.path.includes("/venues/menus"),
            },
            {
              label: "Oferte",
              // icon: "i-heroicons-paper-clip",
              to: "/venues/offers",
              active: route.path.includes("/venues/offers"),
            },
            {
              label: "Șabloane",
              // icon: "i-material-symbols-view-list-outline-rounded",
              to: "/venues/templates",
            },
          ]
        },
        {
          label: "Vizite",
          to: "/venues/leads",
          icon: "i-heroicons-phone-arrow-up-right",
          children: [
            {
              label: "Analitica",
              // icon: "i-heroicons-chart-pie",
              to: "/venues/leads/analytics",
            },
            {
              label: "Lista de apel",
              // icon: "i-heroicons-phone-arrow-up-right",
              to: "/venues/leads/call-list",
            },
            {
              label: "Tabel",
              // icon: "i-heroicons-table-cells",
              to: "/venues/leads/table",
            },
          ]
        },
        {
          label: "Categorii / Produse",
          icon: "i-heroicons-tag",
          to: "/venues/categories",
        },
        {
          label: "Locatii",
          icon: "i-heroicons-map-pin",
          to: "/venues/locations",
        },
      ],
    }
    if (permissionsAllow()) {
      modules.venues.children.unshift({
        label: "Analitica",
        icon: "i-heroicons-chart-pie",
        to: "/venues/analytics",
        badge: {
          color: "primary",
          label: "Nou",
        }
      })
    }
  }

  if (permissionsAllow("hotel")) {
    modules.hotel = {
      tag: "hotel",
      label: "Lago hotel",
      click: () => {
        store.view("hotel");
      },
      children: [
        {
          label: "Rezervari",
          to: "/hotel",
          icon: "i-heroicons-calendar",
          children: [
            {
              label: "Calendar",
              to: "/hotel/bookings/calendar",
            },
            {
              label: "Lista",
              to: "/hotel/bookings/list",
            },
          ],
        },
        {
          label: "Camere",
          icon: "i-material-symbols-bed-outline-rounded",
          to: "/hotel/rooms",
        },
      ],
    }
  }

  if (permissionsAllow("boxcatering")) {
    modules.boxcatering = {
      tag: "boxcatering",
      label: "Boxcatering",
      click: () => {
        store.view("boxcatering");
      },
      children: [
        {
          label: "Oferte",
          icon: "i-heroicons-paper-clip",
          to: "/boxcatering/offers",
          active: route.path.includes("/boxcatering/offers"),
        },
        {
          label: "Banchete",
          icon: "i-material-symbols-file-present-outline-rounded",
          to: "/boxcatering/menus",
          active: route.path.includes("/boxcatering/menus"),
        },
        {
          label: "Comenzi",
          icon: "i-heroicons-shopping-cart",
          to: "/boxcatering/orders",
        },
        {
          label: "Cereri",
          icon: "i-heroicons-inbox",
          to: "/boxcatering/inquiries",
        },
        {
          label: "Categorii",
          icon: "i-heroicons-tag",
          to: "/boxcatering/categories",
        },
        {
          label: "Produse",
          icon: "i-heroicons-shopping-bag",
          to: "/boxcatering/items",
        },
        {
          label: "Ingrediente",
          icon: "i-material-symbols-settings-input-component-outline-rounded",
          to: "/boxcatering/ingredients",
        },
        {
          label: "Setari magazin",
          icon: "i-material-symbols-store-outline-rounded",
          to: "/boxcatering/shop",
        },
        {
          label: "Carusel",
          icon: "i-material-symbols-photo-camera-outline-rounded",
          to: "/boxcatering/hero",
        },
        {
          label: "Vouchere",
          icon: "i-material-symbols-percent-rounded",
          to: "/dashboard/vouchers",
        },
      ],
    }
    if (permissionsAllow()) {
      modules.boxcatering.children.unshift({
        label: "Analitica",
        icon: "i-heroicons-chart-pie",
        to: "/boxcatering/analytics",
      })
    }
  }

  if (permissionsAllow("millerscake")) {
    modules.millerscake = {
      tag: "millerscake",
      label: "Millerscake",
      click: () => {
        store.view("millerscake");
      },
      children: [
        {
          label: "Oferte",
          icon: "i-heroicons-paper-clip",
          to: "/millerscake/offers",
          active: route.path.includes("/millerscake/offers"),
        },
        {
          label: "Comenzi",
          icon: "i-heroicons-shopping-cart",
          to: "/millerscake/orders",
        },
        {
          label: "Categorii",
          icon: "i-heroicons-tag",
          to: "/millerscake/categories",
        },
        {
          label: "Produse",
          icon: "i-heroicons-shopping-bag",
          to: "/millerscake/items",
        },
        {
          label: "Vouchere",
          icon: "i-material-symbols-percent-rounded",
          to: "/dashboard/vouchers",
        },
      ],
    }
    if (permissionsAllow()) {
      modules.millerscake.children.unshift({
        label: "Analitica",
        icon: "i-heroicons-chart-pie",
        to: "/millerscake/analytics",
      })
    }
  }

  if (permissionsAllow("attendance")) {
    modules.attendance = {
      tag: "attendance",
      label: "Prezenta",
      click: () => {
        store.view("attendance");
      },
      children: [
        {
          label: "Personal",
          icon: "i-material-symbols-person-book",
          to: "/attendance/employees",
        },
        {
          label: "Echipa",
          icon: "i-material-symbols-group-outline-rounded",
          to: "/attendance/team",
        },
        {
          label: "Prezenta la zi",
          icon: "i-material-symbols-today-outline-rounded",
          to: "/attendance/day",
        },
        {
          label: "Locatii",
          icon: "i-material-symbols-add-location-alt-outline-rounded",
          to: "/attendance/locations",
        },
      ],
    }
  }

  if (permissionsAllow("marketing")) {
    modules.marketing = {
      tag: "marketing",
      label: "Marketing",
      click: () => {
        store.view("marketing");
      },
      badge: {
        color: "primary",
        label: "Nou",
      },
      children: [
        {
          label: "Clienti",
          icon: "i-material-symbols-person-book",
          to: "/marketing/clients",
        },
        {
          label: "Campanii",
          icon: "i-material-symbols-ads-click-rounded",
          to: "/marketing/campaigns",
        }
      ],
    }
  }

  if (permissionsAccountant()) {
    modules.accounting = {
      tag: "accounting",
      label: "Contabilitate",
      click: () => {
        store.view("accounting");
      },
      children: [
        {
          label: "Rapoarte",
          icon: "i-material-symbols-event-list-outline-rounded",
          to: "/accounting/reports",
        }
      ],
    }
  }
  if (permissionsAccountant("strict")) {
    modules.accounting.children.push(...[
      {
        label: "Ore de munca",
        icon: "i-material-symbols-timer-outline-rounded",
        to: "/accounting/hours",
      },
      {
        label: "Angajati",
        icon: "i-heroicons-user-group",
        to: "/accounting/employees",
      },
      {
        label: "Altele",
        to: "/accounting/other",
        children: [
          {
            label: "Tranzactii",
            icon: "i-heroicons-receipt-refund",
            to: "/accounting/other/invoices",
          },
          {
            label: "Oferte",
            icon: "i-heroicons-paper-clip",
            to: "/accounting/other/offers",
          },
        ]
      },
    ])
  }

  if (permissionsAllow("procurement")) {
    modules.procurement = {
      tag: "procurement",
      label: "Achizitii",
      click: () => {
        store.view("procurement");
      },
      disabled: true,
      badge: {
        color: "orange",
        label: "WIP",
      },
      children: [
        {
          label: "Principal",
          icon: "i-material-symbols-local-shipping-outline-rounded",
          to: "/procurement",
        },
      ],
    }
  }

  if (permissionsAllow()) {
    modules.administration = {
      label: "Administrare",
      icon: "i-material-symbols-admin-panel-settings-outline",
      collapsible: false,
      children: [
        {
          label: "Utilizatori",
          to: "/administration/users",
        },
        // {
        //   label: "Oferte",
        //   to: "/administration/offers",
        // },
        // {
        //   label: "Tranzactii",
        //   to: "/administration/invoices",
        // },
      ],
    }
  }

  modules.footer = {
    label: "Altele",
    children: [
      {
        label: "Setari",
        icon: "i-heroicons-cog-8-tooth",
        collapsible: false,
        children: [
          {
            label: "Profil",
            to: "/settings/profile",
            exact: true,
          },
          {
            label: "Sistem",
            to: "/settings/system",
          },
          {
            label: "Securitate",
            to: "/settings/security",
          },
        ],
      },
    ]
  }

  if (permissionsAllow()) {
    modules.footer.children.push({
      label: "Progres",
      icon: "i-material-symbols-trending-up-rounded",
      to: "/dashboard/plan",
    })
    modules.footer.children.push({
      label: "Taskuri",
      icon: "i-material-symbols-task-outline-rounded",
      to: "/dashboard/tickets",
    })
  }

  return modules
}


// const groups = ref([
//   {
//     key: "common",
//     label: "Comune",
//     commands: common.value.map((link) => ({
//       ...link,
//     })),
//   },
//   ...Object.entries(uncommon.value).map(([key, value]) => ({
//     key,
//     label: value.label,
//     commands: value.children.map((link) => ({
//       ...link,
//     })),
//   })),
// ]);

function permissionsAccountant(type: string = "") {
  if (type == "strict") {
    return ["developer", "admin", "accountant"].includes(store.user.role)
  }

  return ["developer", "admin", "accountant", "manager"].includes(store.user.role) ||
    ["boxcatering_operator_junior", "boxcatering_operator_senior", "boxcatering_manager"].includes(store.user.function);
}
function permissionsAllow(module: string = "") {
  if (!Object.keys(store.user).length) {
    return false
  }
  return (
    ["developer", "admin"].includes(store.user.role) ||
    Object.keys(store.user.permissions).includes(module)
  );
}
function checkUserProfileComplete(): Boolean {
  if (store &&
    store.user &&
    store.user.first_name &&
    store.user.last_name &&
    store.user.function &&
    store.user.location_id &&
    store.user.phone &&
    store.user.email &&
    store.user.image &&
    store.user.id_doc.front.image &&
    store.user.id_doc.back.image &&
    store.user.id_doc.idnp &&
    store.user.id_doc.birthday) {
    return true
  }
  return false
}
</script>

<template>
  <div v-if="store.validated">
    <UBadge v-if="false" color="cyan" variant="soft" class="absolute w-full rounded-none">
      <span class="w-full text-center">
        Platforma in curs de modificare. Unele functionalitati pot fi afectate intre orele 18:00 - 20:00.
      </span>
    </UBadge>

    <UDashboardModal v-if="route.path !== '/settings/profile'" v-model="profileComplete"
      icon="i-material-symbols-warning-rounded" preventClose>
      <template #header>
        <div class="flex gap-4">
          <Icon name="i-material-symbols-warning-rounded" class="h-16 w-20" color="white" />
          <div class="flex flex-col">
            <h2 class="text-lg font-semibold text-gray-900 dark:text-white">Profiulul tau este incomplet.</h2>
            <p class="text-sm text-gray-500 dark:text-gray-400">
              Pentru a continua, completeaza datele contului. Acestea faciliteaza functionarea corecta a platformei.
            </p>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex items-end">
          <UButton to='/settings/profile' label="Mergi la profil" color="green" variant="soft" />
        </div>
      </template>
    </UDashboardModal>

    <!-- <div class="page-gradient" /> -->
    <UDashboardLayout>
      <UDashboardPanel :width="250" :resizable="{ min: 200, max: 300 }" collapsible>
        <UDashboardNavbar v-if="Object.keys(modulesDropdownLinks).length" class="!border-transparent"
          :ui="{ left: 'flex-1' }">
          <template #left>
            <UDropdown class="w-full" :popper="{ strategy: 'absolute' }" :ui="{ width: 'w-full' }"
              :items="[modulesDropdownLinks]" mode="click">
              <UButton class="w-full" variant="ghost" color="gray" trailing-icon="i-heroicons-chevron-down-20-solid">
                <template v-if="viewing">
                  <span class="truncate text-gray-900 font-semibold dark:text-white">{{ viewing.label }}</span>
                </template>
                <template v-else>
                  <span class="truncate text-gray-900 font-semibold dark:text-white">Module</span>
                </template>
              </UButton>

              <template #item="{ item }">
                <div class="w-full flex items-center gap-4"
                  :class="item.tag == store.viewing ? 'text-primary-300 dark:text-primary-500' : ''">
                  <span>{{ item.label }}</span>

                  <UBadge v-if="item.badge" :label="item.badge.label" :color="item.badge.color" variant="soft"
                    size="xs" />
                </div>
              </template>
            </UDropdown>
          </template>
        </UDashboardNavbar>

        <UDashboardSidebar>
          <template #header>
            <UBadge color="primary" variant="soft" size="md" icon="i-heroicons-calendar-days">
              <div class="flex items-center gap-2">
                <Icon name="i-heroicons-calendar" class="h-6 w-6" color="white" />
                <span>
                  {{ formatDateM(new Date()) }}
                </span>
              </div>
            </UBadge>
            <!-- <UDashboardSearchButton label="Cautare" /> -->
          </template>

          <UDashboardSidebarLinks :links="common" />

          <template v-if="viewing">
            <UDivider />

            <UDashboardSidebarLinks :links="viewing.children"
              :ui="{ badge: { color: appConfig.ui.primary, variant: 'soft' } }" />
          </template>

          <div class="flex-1" />

          <template v-if="uncommon.administration">
            <UDashboardSidebarLinks :links="[uncommon.administration]" />

            <UDivider />
          </template>

          <UDashboardSidebarLinks :links="uncommon.footer.children" />

          <UDivider class="sticky bottom-0" />

          <template #footer>
            <div class="w-full mb-8">
              <UserDropdown />
            </div>
          </template>
        </UDashboardSidebar>
      </UDashboardPanel>

      <NuxtPage />

      <CheckinModal />

      <!-- <ErrorModal /> -->
    </UDashboardLayout>
  </div>
  <div v-else>
    <div class="h-screen flex items-center justify-center loading-overlay">
      <div class="loading-gradient" />
      <div class="hidden">
        <NuxtPage />
      </div>
    </div>
  </div>
</template>

<style scoped>
.page-gradient {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: linear-gradient(90deg,
      rgb(var(--color-primary-500) / 0.25) 0,
      #fff 100%);
}

.dark {
  .page-gradient {
    background: linear-gradient(90deg,
        rgb(var(--color-gray-950) / 0.1) 0,
        rgb(var(--color-gray-950)) 100%);
  }
}

.loading-gradient {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: radial-gradient(50% 50% at 50% 50%,
      rgb(var(--color-primary-500) / 0.25) 0,
      #fff 100%);
}

.dark {
  .loading-gradient {
    background: radial-gradient(50% 50% at 50% 50%,
        rgb(var(--color-primary-400) / 0.1) 0,
        rgb(var(--color-gray-950)) 100%);
  }
}

.loading-overlay {
  background-size: 100px 100px;
  background-image: linear-gradient(to right,
      rgb(var(--color-gray-200)) 0.5px,
      transparent 0.5px),
    linear-gradient(to bottom,
      rgb(var(--color-gray-200)) 0.5px,
      transparent 0.5px);
}

.dark {
  .loading-overlay {
    background-image: linear-gradient(to right,
        rgb(var(--color-gray-900)) 0.5px,
        transparent 0.5px),
      linear-gradient(to bottom,
        rgb(var(--color-gray-900)) 0.5px,
        transparent 0.5px);
  }
}
</style>
